import React, {useState} from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Button from '@mui/material/Button';
import { useFormik } from 'formik';
import safeLocalStorage from '../../../storage/safeLocalStorage';
import {  useSelector } from 'react-redux';
import { useEngine } from '../../../components/utils/EngineContext';
import { buildEndURL } from '../../../components/SessionBar';
import { useHistory } from 'react-router';
import styled from '@emotion/styled';

const countries = [ 'Deutschland', 'Belgien', 'Dänemark', 'Frankreich', 'Italien', 'Niedelande', 'Portugal', 'Spanien', 'Vereinigtes Königreich', 'Andere'];

export const isCodeVerified = (code) => {
  return code % 13 === 0
}

const getCode = () => {
  let random = Math.round(Math.random() * 1000000);
  while (!isCodeVerified(random)) {
    random++
  }
  return random
}

export const deleteCode = () => {
  safeLocalStorage.removeItem('grqEmailShareCode');
}

const formSubmitted = () => {
  const grqEmailShareCode = getCode();
  safeLocalStorage.setItem('grqEmailShareCode', grqEmailShareCode);
}



const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--gradient);
  h2 {
    margin-top:0;
    margin-bottom:1em;
  }
  p {
    font-size: 1.1em;
  }
  form {
    margin: 1.2em 0;
    display: flex;
    flex-direction: column;
    gap: 16px;
    button {
      margin-top: 16px;
    }
  }
`

const Mail = () => {

  const rules = useSelector((state) => state.rules);
	const engine = useEngine();
  const history = useHistory();

  const grqEmailShareCode = safeLocalStorage.getItem('grqEmailShareCode');
  if (grqEmailShareCode) history.push(buildEndURL(rules, engine))

  const formik = useFormik({
    initialValues: {
      email: '',
      dataSharing: false,
      country: 'Deutschland'
    },
    onSubmit: values => {
      formSubmitted();
      fetch(API_URL + "/email", {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json'
        },
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
        body: JSON.stringify(values)
      })
      .catch(function(error) {
        console.log(error.message);
      });
      history.push(buildEndURL(rules, engine))
    },
  });


  return (
    <Container>
      <Paper sx={{width: '500px', padding: '1.5em'}}>
        <h2>Glückwunsch : Quiz beendet !</h2>
        <p>Herzlichen Glückwunsch, Sie haben das Quiz beendet!</p>
        <p>Um Ihrer Ergebnisse zu sehen, füllen Sie bitte folgende Inforamtionen aus</p>
        <form onSubmit={formik.handleSubmit}>
          <TextField 
            name='email' 
            type='email' 
            label='E-mail' 
            required 
            value={formik.values.email} 
            onChange={formik.handleChange}
            inputProps={{
              style: {
                fontSize: '1.3em',
                height: '1.3em'
              },
            }}/>
          
          <FormControl>
            <InputLabel>Land</InputLabel>
            <Select
              sx={{fontSize: '1.3em',}}
              value={formik.values.country}
              label="Land"
              name="country"
              onChange={formik.handleChange}
            >
                {countries.map(country => (
                  <MenuItem value={country}>{country}</MenuItem>
                ))}
            </Select>
          </FormControl>

          <FormGroup>
            <FormControlLabel control={<Checkbox sx={{ '& .MuiSvgIcon-root': { fontSize: '1em' } }} name="dataSharing" onChange={formik.handleChange} value={formik.values.dataSharing} />} label="Ich erlaube HOKA, mir ihre neuesten Nachrichten und Angebote per E-Mail zuzusenden" />
          </FormGroup>

          <p>Sie können einen Mitarbeiter nach der HOKA-Richtlinie fragen. Sie können sich jederzeit abmelden.</p>

          <Button sx={{backgroundColor: 'var(--green)', "&:hover": {
            color: 'black',
            backgroundColor: 'var(--green)'
            }}} variant="contained" type="submit">Bestätigen</Button>
        </form>
      </Paper>
    </Container>
  )
}

export default Mail