import { goToQuestion } from 'Actions/actions'
import ShareButton from 'Components/ShareButton'
import animate from 'Components/ui/animate'
import { findContrastedTextColor } from 'Components/utils/colors'
import { IframeOptionsContext } from 'Components/utils/IframeOptionsProvider'
import Meta from 'Components/utils/Meta'
import { AnimatePresence, motion, useSpring } from 'framer-motion'
import { utils } from 'publicodes'
import { default as React, useContext, useEffect, useState } from 'react'
import emoji from 'react-easy-emoji'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { answeredQuestionsSelector } from 'Selectors/simulationSelectors'
import { last } from 'Source/utils'
import tinygradient from 'tinygradient'
import SlidesLayout from '../../../components/SlidesLayout'
import { useSearchParams } from '../../../components/utils/useSearchParams'
import Chart from '../chart'
import DefaultFootprint from '../DefaultFootprint'
import HorizontalSwipe from '../HorizontalSwipe'
import BallonGES from './ballonGES.svg'
import { ActionButton, IntegratorActionButton } from './Buttons'
import IframeDataShareModal from './IframeDataShareModal'
import Petrogaz from './Petrogaz'
const { encodeRuleName } = utils
import { Header } from '../App'
import { FFFButton } from './Buttons'
import safeLocalStorage from '../../../storage/safeLocalStorage';
import { isCodeVerified } from './Mail'
import { useHistory } from 'react-router';
import { deleteCode } from './Mail';
import {
	deletePreviousSimulation,
	resetActionChoices,
	resetSimulation,
	resetTutorials,
	resetStoredTrajets,
} from 'Actions/actions'

const gradient = tinygradient([
		'#78e08f',
		'#e1d738',
		'#f6b93b',
		'#b71540',
		'#000000',
	]),
	colors = gradient.rgb(20)

const getBackgroundColor = (score) =>
	colors[
		Math.round((score < 2000 ? 0 : score > 20000 ? 19000 : score - 2000) / 1000)
	]

// details=a2.6t2.1s1.3l1.0b0.8f0.2n0.1
const rehydrateDetails = (encodedDetails) =>
	encodedDetails &&
	encodedDetails
		.match(/[a-z][0-9]+\.[0-9][0-9]/g)
		.map(([category, ...rest]) => [category, 1000 * +rest.join('')])
		// Here we convert categories with an old name to the new one
		// 'biens divers' was renamed to 'divers'
		.map(([category, ...rest]) =>
			category === 'b' ? ['d', ...rest] : [category, ...rest]
		)

const sumFromDetails = (details) =>
	details.reduce((memo, [name, value]) => memo + value, 0)

export default ({}) => {
	const [searchParams, setSearchParams] = useSearchParams()
	const encodedDetails = searchParams.get('details')
	const slideName = searchParams.get('diapo') || 'bilan';

	const history = useHistory();

	const grqEmailShareCode = safeLocalStorage.getItem('grqEmailShareCode');
	if (!grqEmailShareCode || !isCodeVerified(grqEmailShareCode)) {
		history.push('/mail')
	}


	const rehydratedDetails = rehydrateDetails(encodedDetails)

	const score = sumFromDetails(rehydratedDetails)
	const headlessMode =
		!window || window.navigator.userAgent.includes('HeadlessChrome')

	const dispatch = useDispatch(),
		answeredQuestions = useSelector(answeredQuestionsSelector)

	const slideProps = {
		score,
		details: Object.fromEntries(rehydratedDetails),
		headlessMode,
	}

	const Component = {
		bilan: Budget,
		pétrogaz: Petrogaz,
	}[slideName]

	const next = () => {
			const nextSlide = slideName === 'bilan' ? 'pétrogaz' : 'bilan'
			setSearchParams({ diapo: nextSlide, details: encodedDetails })
		},
		previous = next

	return (
		<div>
			<IframeDataShareModal data={rehydratedDetails} />
			<Budget {...slideProps} />
		</div>
	)
}

const CarbonBudget = (props) => {
	const { integerValue, score, decimalValue, integratorActionText } = props;
	const impactPart = Math.round(score / 9900 * 100);
	return (
		<div
			css={`
				position: relative;
				width:100%;
				padding: 32px 32px 64px;
				display: flex;
				flex-direction: column;
				align-items: center;
				background-color: var(--yellow);
				* {
					color: black;
					font-family: 'FuturaPT';
					margin: 0;
				}
				p:first-child {
					font-size: 50px;
					line-height: 60px;
					font-weight: bold;
					margin-bottom: 0;
				}
				p:last-child {
					font-size: 18px;
					font-weight: 300;
					margin-bottom: 0;
				}
				@media (max-width: 800px) {
					p:first-child {
						font-size: 40px;
						font-weight: bold;
						margin-bottom: 4px;
					}
					p:last-child, #co2 {
						font-size: 20px;
						font-weight: 300;
					}
				}
				p.indicator {
					font-size: 1em;
					line-height: 1.1em;
					letter-spacing: 2.4px;
					margin: 12px 0 0 0;
					span {
						font-weight: bold;
					}
				}
				p.precision {
					margin: 6px 0 0 0;
					width: 75%;
					font-size: 0.8em;
					font-size: 0.8em;
					letter-spacing: 2.4px;
					span {
						font-weight: bold;
					}
				}
				span.minor {
					font-size: 0.8em;
					font-weight: normal !important;
				}
				a {
					position: absolute;
					bottom: 0;
					left: 50%;
					transform: translate(-50%, 50%);
				}
			`}
		>
			<p>{integerValue},<span>{decimalValue} TONNEN</span> <span id="co2"></span></p>
			<p>(CO2eq) erzeugt durch Ihr Lauftraining<br/></p>
			<p className='precision'>Durchschnittlich, machen Ihre Laufgewohnheiten <span>{impactPart} %</span> des gesamten CO2-Fußabdrucks aus: <span>9,9 tonnen</span> (nicht nur sportbezogen / französische Daten)</p>
			{!integratorActionText && (
				<ActionButton text="Schreiten Sie zur Tat" score={score} />
			)}
		</div>
	)
}


const Budget = ({ score, details, headlessMode }) => {
	//	Configuration is try and test, feeling, really
	const valueSpring = useSpring(0, {
		mass: 10,
		stiffness: 50,
		damping: 60,
	})

	const [value, setValue] = useState(0)

	useEffect(() => {
		const unsubscribe = valueSpring.onChange((v) => {
			setValue(v)
		})

		headlessMode ? setValue(score) : valueSpring.set(score)

		return () => unsubscribe()
	})

	const backgroundColor = getBackgroundColor(value).toHexString(),
		backgroundColor2 = getBackgroundColor(value + 2000).toHexString(),
		textColor = findContrastedTextColor(backgroundColor, true),
		roundedValue = (value / 1000).toLocaleString('fr-FR', {
			maximumSignificantDigits: 2,
			minimumSignificantDigits: 2,
		}),
		integerValue = roundedValue.split(',')[0],
		decimalValue = roundedValue.split(',')[1],
		shareImage = generateImageLink(window.location)

	const { integratorYoutubeVideo, integratorActionText, integratorActionUrl } =
		useContext(IframeOptionsContext)

	const dispatch = useDispatch(),
		answeredQuestions = useSelector(answeredQuestionsSelector)

	return (
		<div>
			<Meta
				title="Mon empreinte climat"
				description={`Mon empreinte climat est de ${roundedValue} tonnes de CO2e. Mesure la tienne !`}
				image={shareImage}
				url={window.location}
			/>
			<div
				className=""
				id="fin"
				css={`
					color: ${textColor};
					height: 100vh;
					margin: 0 auto;
					border-radius: 0.6rem;
					display: flex;
					flex-direction: column;
					justify-content: space-between;
					text-align: center;
					font-size: 110%;
				`}
			>
				<div>
					<div css={`
							width: 100%;
						`}>
						<Header />
						</div>
						<CarbonBudget 
							integerValue={integerValue}
							score={score}
							decimalValue={decimalValue}
							integratorActionText={integratorActionText}
						/>
						<div css={`
							background-color: white;
							padding: 16px;
						`}>
						<Chart
							noAnimation
							details={details}
							links
							color={textColor}
							noText
							noCompletion
							valueColor={textColor}
						/>
					</div>

					
				</div>
				
				<div css={`
					display: flex;
					align-items: center;
					justify-content: center;
					gap: 16px;
					padding: 16px;
				`}>
					{/* <FFFButton 
						type='Link'
						to="/simulateur/bilan"
						colorType="fff_blue"
						fontSize="10px"
						fontWeight="normal"
						onClick={() => {
							dispatch(goToQuestion(last(answeredQuestions)))
						}}
						text="Zurück zur Simulation"
					/> */}
					{integratorActionText && <ActionButton text="Réduire mon empreinte" />}
					{/* <DocumentationEndButton ruleName={'bilan'} color={textColor} /> */}
					<StartAgainButton 
						color={textColor}
						label="Neu starten"
					/>
					<ShareButton
						text="Here is my running footprint. Measure Yours!"
						url={window.location}
						title={'Green Runner Quiz'}
						color={textColor}
						label="Meine Ergebnisse teilen"
					/>
					
				</div>


				

				{integratorActionText && integratorActionUrl && (
					<IntegratorActionButton />
				)}

				{/* {integratorYoutubeVideo && (
					<div
						class="videoWrapper"
						css={`
							iframe {
								width: 100%;
							}
						`}
					>
						<iframe
							width="560"
							height="315"
							src={integratorYoutubeVideo}
							title="YouTube video player"
							frameborder="0"
							allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
							allowfullscreen
						></iframe>
					</div>
				)} */}

				
			</div>
		</div>
	)
}




export const generateImageLink = (location) =>
	'https://aejkrqosjq.cloudimg.io/v7/' +
	location.origin +
	'/.netlify/functions/ending-screenshot?pageToScreenshot=' +
	encodeURIComponent(location)

export const DocumentationEndButton = ({ ruleName, color }) => (
	<FFFButton
		type='Link'
		to={'/documentation/' + encodeRuleName(ruleName)}
		text="Understand the calculation"
		colorType="fff_white"
		fontSize="1.3em"
		fontWeight="normal"
	/>
)

const StartAgainButton = ({ text, url, title, color, label }) => {
	const history = useHistory();
	const dispatch = useDispatch()
	return (
		<FFFButton
			colorType="fff_blue"
			title="Cliquez pour partager le lien"
			fontSize="1.3em"
			fontWeight="normal"
			text={label}
			onClick={() => {
				dispatch(resetSimulation())
				dispatch(resetActionChoices())
				dispatch(deletePreviousSimulation())
				dispatch(resetTutorials())
				dispatch(resetStoredTrajets())
				deleteCode()
				history.push('/simulateur/bilan')
			}}
		/>
	)
}
