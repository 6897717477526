import emoji from 'react-easy-emoji'
import { useDispatch } from 'react-redux'
import { actionImg } from '../../components/SessionBar'
import { skipTutorial } from '../../actions/actions'
import { FFFButton } from './fin/Buttons'

export default ({ value, unit }) => {
	const dispatch = useDispatch()
	return (
		<div css={`
			width: 100%;
			height: 100%;
			display: flex;
			flex-direction: column;
			align-items: center;
			text-align: center;
			background: var(--gradient);
			h1 {
				font-size: 50px;
				margin: 60px 0px 30px;
				color: black;
				letter-spacing: 0px;
			}
			> div {
				margin: 16px 0;
			}
			* {
				font-family: 'FuturaPT';
			}
			p {
				color: white;
				font-size: 1em;
				line-height: 1.1em;
				margin-bottom: 8px;
				padding: 0;
			}
			button {
				margin-top: 32px;
			}
		`}>
			<h1>
			Schreiten Sie zur Tat
			</h1>
			
			<div>
				<p>Sie haben Ihre erste Simulation beendet. Gut gemacht !</p>
				<p>
				Sie kennen jetzt Ihren CO2-Fußabdruck, der auf 0 geschätzt wird und Sie haben wahrscheinlich schon einige Ideen wie Sie diesen reduzieren können.
				</p>
				<p>
				Hier ist eine Liste möglicher Maßnahmen welche Ihenen helfen können : 
				</p>
			</div>
			<div>
				<p>{emoji('✅')} wählen Sie die welche Sie interessieren</p>
				<p>
					{emoji('❌')} verwerfen Sie diejenigen welche Ihnen zu ehrgeizig oder unangemessen erscheinen
				</p>
				<p>
					<b>?</b> Um an Präzision zu gewinnen, führen einige Aktionen zu weiteren Fragen.
				</p>
			</div>
			<FFFButton 
				colorType="fff_blue"
				onClick={() => dispatch(skipTutorial('actions'))}
				text="Beginnen"
			/>
		</div>
	)
}
