import NumberedMosaic from './select/NumberedMosaic';
import SelectDevices from './select/SelectDevices';
import { DottedName } from 'Rules';

const mosaicQuestions: Array<{
	question: string
	description: string
	isApplicable: Function
	component: React.FunctionComponent
	dottedName: DottedName
}> = [
	{
		dottedName: 'crosstraining',
		question: 'Welche anderen Disziplinen praktizieren Sie, um Ihre Laufleistung zu steigern?',
		isApplicable: (dottedName: DottedName) =>
			dottedName.includes('crosstraining') &&
			dottedName.includes(' . présent'),
		component: SelectDevices,
	},
	{
		dottedName: 'equipement . matériel . textile',
		question: 'Wie viel von der folgenden Ausrüstung kaufen Sie im Laufe des Jahres?',
		isApplicable: (dottedName: DottedName) =>
			dottedName.includes('equipement . matériel . textile') &&
			dottedName.includes(' . nombre'),
		component: NumberedMosaic,
	},
	{
		dottedName: 'equipement . matériel . elec',
		question: 'Besitzen Sie eine der folgenden Ausrüstungen für Ihr Lauftraining?',
		isApplicable: (dottedName: DottedName) =>
			dottedName.includes('equipement . matériel . elec') &&
			dottedName.includes(' . nombre'),
		component: NumberedMosaic,
	},
	{
		dottedName: 'training . daily . empreinte transport',
		question: 'Wie lange brauchen Sie, um zu diesen Trainingseinheiten zu pendeln? (in Minuten - Hin- und Rückfahrt)',
		isApplicable: (dottedName: DottedName) =>
			dottedName.includes('training . daily . empreinte transport') &&
			dottedName.includes(' . minutes'),
		component: NumberedMosaic,
	},
	{
		dottedName: 'training . daily . empreinte lieu',
		question: 'Wie viele Stunden trainieren Sie Wöchntlich wie folgt?',
		isApplicable: (dottedName: DottedName) =>
			dottedName.includes('training . daily . empreinte lieu') &&
			dottedName.includes(' . hour'),
		component: NumberedMosaic,
	},
	{
		dottedName: 'training . exceptional . empreinte',
		question: 'Wie viele Stungen  sind  Sie jedes Jahr unterwegs, um zum Trainigslager zu fahren?',
		isApplicable: (dottedName: DottedName) =>
			dottedName.includes('training . exceptional . empreinte') &&
			dottedName.includes(' . hours'),
		component: NumberedMosaic,
	},
	{
		dottedName: 'training . training camp . transport',
		question: 'Wie viele Stungen  sind  Sie jedes Jahr unterwegs, um zum Trainigslager zu fahren?',
		isApplicable: (dottedName: DottedName) =>
			dottedName.includes('training . training camp . transport') &&
			dottedName.includes(' . hours'),
		component: NumberedMosaic,
	},
	{
		dottedName: 'crosstraining . transport . empreinte transport',
		question: 'Wie lange brauchen Sie wöchentlich, um zu Ihren Cross-Training Aktivitäten zu pendeln? (Fitnessstudio, Fahrrad, Schwimmen, usw.)  (in Stunden nach Transportart - Rundfahrt - Skifahren ausgeschlossen)',
		isApplicable: (dottedName: DottedName) =>
			dottedName.includes('crosstraining . transport . empreinte transport') &&
			dottedName.includes(' . hours'),
		component: NumberedMosaic,
	},
	{
		dottedName: 'crosstraining . winter . transport . empreinte transport',
		question: 'Wie lange verbringen Sie durchschnittlich in jeder Art von Transport, um in einer Wintersaison zum Skitouren und Langlaufen zu gehen? (in Stunden - Hin- und Rückfahrten)',
		isApplicable: (dottedName: DottedName) =>
			dottedName.includes('crosstraining . winter . transport . empreinte transport') &&
			dottedName.includes(' . hours'),
		component: NumberedMosaic,
	},
	{
		dottedName: 'training . food',
		question: 'Was essen und trinken Sie bei einer gewöhnlichen Trainingseinheit?',
		isApplicable: (dottedName: DottedName) =>
			dottedName.includes('training . food') &&
			dottedName.includes(' . number'),
		component: NumberedMosaic,
	},
	{
		dottedName: 'competition',
		question: 'An welcher Art von Rennen nehmen Sie teil?',
		isApplicable: (dottedName: DottedName) =>
			dottedName.includes('competition') &&
			dottedName.includes(' . présent'),
		component: SelectDevices,
	},
	{
		dottedName: 'competition . international . events',
		question: 'An wie vielen internationalen oder nationalen Rennen nehemen Sie jährlich teil?',
		isApplicable: (dottedName: DottedName) =>
			dottedName.includes('competition . international . events') &&
			dottedName.includes(' . number'),
		component: NumberedMosaic,
	},
	{
		dottedName: 'competition . international . transport',
		question: 'Wie viele Stunden sind Sie unterwegs um zu den nationalen und internationalen Wettbewerben zu gelangen?',
		isApplicable: (dottedName: DottedName) =>
			dottedName.includes('competition . international . transport') &&
			dottedName.includes(' . hours'),
		component: NumberedMosaic,
	},
	{
		dottedName: 'competition . regional . events',
		question: 'An wie vielen regionalen Rennen nehmen Sie jährlich teil?',
		isApplicable: (dottedName: DottedName) =>
			dottedName.includes('competition . regional . events') &&
			dottedName.includes(' . number'),
		component: NumberedMosaic,
	},
	{
		dottedName: 'competition . regional . transport',
		question: 'Wie viele Stunden sind Sie unterwegs, um zu den regionalen Wettbewerben zu gelangen?',
		isApplicable: (dottedName: DottedName) =>
			dottedName.includes('competition . regional . transport') &&
			dottedName.includes(' . hours'),
		component: NumberedMosaic,
	},
	{
		dottedName: 'competition . food',
		question: 'Was essen und trinken Sie  pro Stunde Laufwettkampf?',
		isApplicable: (dottedName: DottedName) =>
			dottedName.includes('competition . food') &&
			dottedName.includes(' . number'),
		component: NumberedMosaic,
	},
	{
		dottedName: 'frankfurt . preparation . transport',
		question: 'Wie lange hast du insgesamt gebraucht, um zu diesen Rennen und Trainingslagern zu gelangen? (in Stunden nach Transportart - Hin- und Rückfahrt)',
		isApplicable: (dottedName: DottedName) =>
			dottedName.includes('frankfurt . preparation . transport') &&
			dottedName.includes(' . hours'),
		component: NumberedMosaic,
	},
	{
		dottedName: 'frankfurt . marathon . transport',
		question: 'Wie viele Stunden waren Sie unterwegs, um zum Mainova Frankfurt Marathon zu kommen? (Rundreise)',
		isApplicable: (dottedName: DottedName) =>
			dottedName.includes('frankfurt . marathon . transport') &&
			dottedName.includes(' . hours'),
		component: NumberedMosaic,
	},
	{
		dottedName: 'frankfurt . marathon . food',
		question: 'Was haben Sie vor, während und nach dem Rennen gegessen/getrunken?',
		isApplicable: (dottedName: DottedName) =>
			dottedName.includes('frankfurt . marathon . food') &&
			dottedName.includes(' . number'),
		component: NumberedMosaic,
	},
	{
		dottedName: 'digital . streaming . sport events',
		question: 'Ist es relevant? Was auch immer angeschauft wird, es verändert nicht den CO2-Fußabdruck?',
		isApplicable: (dottedName: DottedName) =>
			dottedName.includes('digital . streaming . sport events') &&
			dottedName.includes(' . présent'),
		component: SelectDevices,
	},
	
	
	////////////////////////////
	////////// MFC STUFF
	////////////////////////////

	// {
	// 	dottedName: 'divers . textile',
	// 	question: 'Quels vêtements achetez-vous neufs en général dans une année?',
	// 	isApplicable: (dottedName: DottedName) =>
	// 		dottedName.includes('divers . textile') &&
	// 		dottedName.includes(' . nombre'),
	// 	component: NumberedMosaic,
	// },
	// {
	// 	dottedName: 'equipement . matériel',
	// 	question: 'Sur une saison, combien d’équipements suivants achetez-vous pour la pratique du football?',
	// 	isApplicable: (dottedName: DottedName) =>
	// 		dottedName.includes('equipement . matériel') &&
	// 		dottedName.includes(' . nombre'),
	// 	component: NumberedMosaic,
	// },
	// {
	// 	dottedName: 'pratique . loisir',
	// 	question: 'En moyenne, combien de séances de football (loisir uniquement) faites-vous par mois dans les contextes suivants?',
	// 	isApplicable: (dottedName: DottedName) =>
	// 		dottedName.includes('pratique . loisir') &&
	// 		dottedName.includes(' . nombre pratiques'),
	// 	component: NumberedMosaic,
	// },
	// {
	// 	dottedName: 'pratique . loisir . déplacement',
	// 	question: 'Sur un mois, combien de fois utilisez-vous les moyens de transport suivants pour aller pratiquer le football loisir?',
	// 	isApplicable: (dottedName: DottedName) =>
	// 		dottedName.includes('pratique . loisir . déplacement') &&
	// 		dottedName.includes(' . nombre déplacements'),
	// 	component: NumberedMosaic,
	// },
	// {
	// 	dottedName: 'pratique . futsal . déplacement domicile',
	// 	question: 'Sur un mois, combien de fois utilisez-vous les moyens de transport suivants pour vous rendre à l’entraînement et à vos matchs de futsal à domicile?',
	// 	isApplicable: (dottedName: DottedName) =>
	// 		dottedName.includes('pratique . futsal . déplacement domicile') &&
	// 		dottedName.includes(' . nombre déplacements'),
	// 	component: NumberedMosaic,
	// },
	// {
	// 	dottedName: 'pratique . futsal . déplacement exterieur',
	// 	question: 'Sur un mois en saison de compétition, combien de fois utilisez-vous les moyens de transport suivants pour vous rendre à vos matchs de futsal à l’extérieur?',
	// 	isApplicable: (dottedName: DottedName) =>
	// 		dottedName.includes('pratique . futsal . déplacement exterieur') &&
	// 		dottedName.includes(' . nombre déplacements'),
	// 	component: NumberedMosaic,
	// },
	// {
	// 	dottedName: 'pratique . club . déplacement domicile',
	// 	question: 'Sur un mois en saison de compétition, combien de fois utilisez-vous les moyens de transport suivants pour vous rendre à l’entraînement et à vos matchs à domicile?',
	// 	isApplicable: (dottedName: DottedName) =>
	// 		dottedName.includes('pratique . club . déplacement domicile') &&
	// 		dottedName.includes(' . nombre déplacements'),
	// 	component: NumberedMosaic,
	// },
	// {
	// 	dottedName: 'pratique . club . déplacement exterieur national',
	// 	question: 'Sur un mois en saison de compétition, combien d’heures passez-vous par moyen de transport pour vous rendre sur des matchs à l’extérieur l’échelle nationale?  ',
	// 	isApplicable: (dottedName: DottedName) =>
	// 		dottedName.includes('pratique . club . déplacement exterieur national') &&
	// 		dottedName.includes(' . nombre heures'),
	// 	component: NumberedMosaic,
	// },
	// {
	// 	dottedName: 'pratique . club . déplacement exterieur hors national',
	// 	question: 'Sur un mois saison de compétition, combien de fois utilisez-vous les moyens de transport suivants pour vous rendre à vos matchs à l’extérieur? ',
	// 	isApplicable: (dottedName: DottedName) =>
	// 		dottedName.includes('pratique . club . déplacement exterieur hors national') &&
	// 		dottedName.includes(' . nombre déplacements'),
	// 	component: NumberedMosaic,
	// },
	// {
	// 	dottedName: 'pratique . international . déplacements',
	// 	question: 'Sur une année, combien d\'heures passez-vous par moyen de transport pour vous rendre en tant que joueur sur des lieux de compétition internationale?',
	// 	isApplicable: (dottedName: DottedName) =>
	// 		dottedName.includes('pratique . international . déplacements') &&
	// 		dottedName.includes(' . nombre heures'),
	// 	component: NumberedMosaic,
	// },
	// {
	// 	dottedName: 'pratique . alimentation . séance club',
	// 	question: 'Sur une séance club (entraînement, match), quelle est votre consommation alimentaire moyenne (avant, pendant, et après)?',
	// 	isApplicable: (dottedName: DottedName) =>
	// 		dottedName.includes('pratique . alimentation . séance club') &&
	// 		dottedName.includes(' . nombre'),
	// 	component: NumberedMosaic,
	// },
	// {
	// 	dottedName: 'pratique . alimentation . séance loisir',
	// 	question: 'Sur une séance loisir, quelle est votre consommation alimentaire moyenne (avant, pendant, et après)?',
	// 	isApplicable: (dottedName: DottedName) =>
	// 		dottedName.includes('pratique . alimentation . séance loisir') &&
	// 		dottedName.includes(' . nombre'),
	// 	component: NumberedMosaic,
	// },
	// {
	// 	dottedName: 'spectateur . déplacements',
	// 	question: 'A quelles échelles vous déplacez-vous pour assister à des matchs de football?',
	// 	isApplicable: (dottedName: DottedName) =>
	// 		dottedName.includes('spectateur . déplacements') &&
	// 		dottedName.includes(' . présent'),
	// 	component: SelectDevices,
	// },
	// {
	// 	dottedName: 'spectateur . déplacements . locaux',
	// 	question: 'Sur un mois, combien de fois utilisez-vous les moyens de transport suivants pour aller assister à un match à l’échelle locale ou régionale?',
	// 	isApplicable: (dottedName: DottedName) =>
	// 		dottedName.includes('spectateur . déplacements . locaux') &&
	// 		dottedName.includes(' . nombre déplacements'),
	// 	component: NumberedMosaic,
	// },
	// {
	// 	dottedName: 'spectateur . déplacements . nationaux',
	// 	question: 'Sur une année, combien d’heures passez-vous par moyen de transport pour vous rendre comme spectateur sur des événements sportifs d’envergure nationale ou internationale?',
	// 	isApplicable: (dottedName: DottedName) =>
	// 		dottedName.includes('spectateur . déplacements . nationaux') &&
	// 		dottedName.includes(' . nombre heures'),
	// 	component: NumberedMosaic,
	// },
	// {
	// 	dottedName: 'spectateur . consommation . boissons',
	// 	question: 'En général, quelle quantité de boisson consommez-vous devant et en marge de ces matchs?',
	// 	isApplicable: (dottedName: DottedName) =>
	// 		dottedName.includes('spectateur . consommation . boissons') &&
	// 		dottedName.includes(' . volume'),
	// 	component: NumberedMosaic,
	// },
	// {
	// 	dottedName: 'spectateur . consommation . equipement',
	// 	question: 'Pour aller assister à ces matchs, combien d’équipements achetez-vous par an (hors équipement utilisés pour la pratique du football)?',
	// 	isApplicable: (dottedName: DottedName) =>
	// 		dottedName.includes('spectateur . consommation . equipement') &&
	// 		dottedName.includes(' . nombre'),
	// 	component: NumberedMosaic,
	// },
	// {
	// 	dottedName: 'spectateur . téléspectateur . lieu . support',
	// 	question: 'En moyenne, combien de matchs regardez-vous par mois sur les supports suivants?',
	// 	isApplicable: (dottedName: DottedName) =>
	// 		dottedName.includes('spectateur . téléspectateur . lieu . support') &&
	// 		dottedName.includes(' . nombre'),
	// 	component: NumberedMosaic,
	// }
	// ,
	// {
	// 	dottedName: 'spectateur . téléspectateur . lieu . contexte',
	// 	question: ' En moyenne, combien de matchs regardez-vous par mois dans les contextes suivants?',
	// 	isApplicable: (dottedName: DottedName) =>
	// 		dottedName.includes('spectateur . téléspectateur . lieu . contexte') &&
	// 		dottedName.includes(' . nombre'),
	// 	component: NumberedMosaic,
	// },
	// {
	// 	dottedName: 'spectateur . téléspectateur . boissons',
	// 	question: 'En moyenne, un soir/après-midi de match, combien consommez-vous de :',
	// 	isApplicable: (dottedName: DottedName) =>
	// 		dottedName.includes('spectateur . téléspectateur . boissons') &&
	// 		dottedName.includes(' . volume'),
	// 	component: NumberedMosaic,
	// },
]

export default mosaicQuestions
